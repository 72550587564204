export default {
  // global theme for the app
  globalTheme: 'light', // light | dark

  // dark theme colors
  dark: {
    textbase: '#edf0f2',
    background: '#05090c',
    // background: '#8a000',
    surface: '#111b27',
    primary: '#0096c7',
    // primary: '#8a0000',
    secondary: '#5c7f94',
    accent: '#165f3c',
    error: '#FF5252',
    info: '#2196F3',
    success: '#05c075',
    warning: '#FFC107',
  },

  // dark theme colors
  darkqm: {
    textbase: '#edf0f2',
    // background: '#05090c',
    background: '#8a0000',
    surface: '#111b27',
    // primary: '#0096c7',
    primary: '#e31e25',
    secondary: '#e0e0e3',
    accent: '#165f3c',
    error: '#FF5252',
    info: '#2196F3',
    success: '#05c075',
    warning: '#FFC107',
  },

  // light theme colors
  light: {
    textbase: '#222222',
    background: '#ffffff',
    surface: '#f2f5f8',
    // primary: '#0096c7',
    primary: '#e31e25',
    secondary: '#424242',
    // secondary: '#c7c7c7',
    accent: '#165f3c',
    error: '#ef476f',
    info: '#2196F3',
    success: '#05c075',
    warning: '#ffd166',
  },
}
