import configs from '../../configs'

const state = () => ({
  product: configs.product,

  // app theme dark or light
  globalTheme: configs.theme.globalTheme,

  // themes and layout configurations
  isRTL: configs.theme.isRTL
})

export default state
