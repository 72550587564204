/* eslint-disable camelcase */

import Vue from 'vue'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'

import {
  alpha,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  dimensions,
  email,
  excluded,
  ext,
  image,
  integer,
  is,
  is_not,
  length,
  max,
  max_value,
  mimes,
  min,
  min_value,
  numeric,
  oneOf,
  regex,
  required,
  required_if,
  size,
} from 'vee-validate/dist/rules'

extend('alpha', alpha)
extend('alpha_dash', alpha_dash)
extend('alpha_num', alpha_num)
extend('alpha_spaces', alpha_spaces)
extend('between', between)
extend('confirmed', confirmed)
extend('digits', digits)
extend('dimensions', dimensions)
extend('email', email)
extend('excluded', excluded)
extend('ext', ext)
extend('image', image)
extend('integer', integer)
extend('is', is)
extend('is_not', is_not)
extend('length', length)
extend('max', max)
extend('max_value', max_value)
extend('mimes', mimes)
extend('min', min)
extend('min_value', min_value)
extend('numeric', numeric)
extend('oneOf', oneOf)
extend('regex', regex)
extend('oneOf', oneOf)
extend('required', required)
extend('required_if', required_if)
extend('size', size)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export { localize }
