
export default {
  /*
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      htmlAttrs: {
        // myAttribute: 'My Value',
        lang: this.$i18n.locale,
        ...i18nHead.htmlAttrs,
      },
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo.description'),
        },
        ...i18nHead.meta,
      ],

      link: [
        {
          hid: 'apple-touch-icon',
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png',
        },
        ...i18nHead.link,
      ],
    }
  },
  */
}
