
import { menu2 as menu } from './menus.js'

export default {
  props: {
    isFluid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      drawer: null,
      menu,
    }
  },
}
