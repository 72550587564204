export default {
  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    this.$vuetify.theme.dark = theme === 'dark'
    state.globalTheme = theme
  },

  setRTL: (state, isRTL) => {
    this.$vuetify.rtl = isRTL
    state.isRTL = isRTL
  },
}
