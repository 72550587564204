export const menu1 = [
  {
    title: 'Solutions',
  },
  {
    title: 'Pricing',
  },
  {
    title: 'Docs',
  },
  {
    title: 'More',
  },
]

export const menu2 = [
  {
    title: 'Services',
    to: '/services',
  },
  {
    title: 'Team',
    to: '/staff',
  },
  {
    title: 'Pricing',
    to: '/pricing',
  },
  {
    title: 'About Us',
    to: '/aboutUs',
  },
]

export const menuServices = [
  {
    title: 'Office Cleaning',
    to: '/services/office',
  },
  {
    title: 'Home Cleaning',
    to: '/services/home-and-apartments',
  },
  {
    title: 'Move Out/In Cleaning',
    to: '/services/moveOut',
  },
  {
    title: 'After Party Cleaning',
    to: '',
  },
  {
    title: 'Final Post Construction Cleaning',
    to: '',
  },
]

export default {
  menu1,
  menu2,
}
