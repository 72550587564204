
export default {
  data() {
    return {
      links: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'About us',
          to: '/aboutUs',
        },
        {
          title: 'Services',
          to: '/services',
        },
        {
          title: 'Team',
          to: '/staff',
        },
        {
          title: 'Pricing',
          to: '/pricing',
        },
        {
          title: 'Contact us',
          to: '/contactUs',
        },
      ],
    }
  },
}
