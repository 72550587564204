
export default {
  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: '',
    },
    textBlockList: {
      type: Array,
      default: () => {
        return []
      },
    },
    btn1: {
      type: Object,
      default: () => {
        return {
          label: 'Call Now',
          telLink: 'qmData.telLink',
        }
      },
    },
    ximage: {
      type: String,
      default: '',
    },
  },
}
