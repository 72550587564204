import en from 'vee-validate/dist/locale/en.json'

const messages = {
  validation: en.messages,
}

export default {
  xver: 'en-US',
  qmData: {
    copyright: 'Quality Maids of Las Vegas, LLC. 2023 - All Rights Reserved',
    tel: '(702) 651-6243',
    telLink: 'tel:(702) 651-6243',
  },
  button: {
    label: {
      yes: 'Yes',
      no: 'No',
      accept: 'Accept',
      cancel: 'Cancel',
      save: 'Save',
      ignore: 'Ignore',
      close: 'Close',
    },
  },
  seo: {
    slogan: 'Food is part of your Lifestyle',
    description:
      'Get your favorite food, discover new eateries and enjoy the freedom to search without hassles or long waits on the phone.',
  },
  web: {
    404: {
      header: 'Oops!',
      title: "We can't find the page you're looking for.",
      textBox:
        'The page you are looking for might have been removed, had its name changed or is temporarily unavailable. Please check the URL and try again. You can also return to our homepage',
      homeText: 'Homepage',
      endingText: 'or check out other useful pages',
    },

    topBanner: {
      titleStart: 'Looking for a Professional Cleaning Company?',
      titleEnd: '',
      textBlock: '',
      btnText: 'Our Services in Las Vegas & Henderson',
    },

    whatIs: {
      title: 'What is YaMenu?',
      textBlock:
        'We are the new way to elevate your menu to another level. We provide a virtual assistance service in order to create a link between you and your clients through a digital platform. We delight your consumers with your menu and our creativity, and we take care of receiving, processing and arranging the delivery of your products or dishes, efficiently and safely.',
    },

    howItWorks: {
      title: 'How does it work?',
      register: {
        title: 'Register your business',
        txt:
          "Fill out a small form to check if you qualify as YaMenu partner. That's pretty easy and takes a short time.",
      },
      selectYourPrepaid: {
        title: 'Choose you Plan',
        txt:
          'Select YaMenu as your partner to get online orders processed quickly and accurately.',
      },
      sendYourInfo: {
        title: 'Send your info',
        txt:
          'Share with us your menus, photos of your products, schedules and a short description of your dishes.',
      },
      setYourDeliveryZone: {
        title: 'Set your Delivery Area',
        txt:
          'Use your own delivery system or choose any other available in your area. You can also offer take-out or dine-in.',
      },
      yourMenusNowPublished: {
        title: 'Time to Publish your Menus',
        txt:
          'Once finished the previous steps, we will publish your menus. It is important to let you know your social contacts, friends and current customers that now you are a YaMenu partner.',
      },
      saveTime: {
        title: 'Save Time',
        txt:
          'Let us take care of the cumbersome, time consuming order processing for you, while you focus on your core business "preparing delicious meals for your customers".',
      },
    },

    prepaid: {
      title: 'No hassle fixed rates',
      textBlock:
        'Only pay a fixed rate for orders received. No long term contracts and absolutely commission FREE',
      price: {
        freeListing: 'Free',
        freePic: '/images/prepaid/free-en.jpg',
        promo: 'Promo',
        promoPic: '/images/prepaid/40-promo-en.jpg',
        pre40: 'Pre 40',
        pre40Pic: '/images/prepaid/40-en.jpg',
        pre100: 'Pre 100',
        pre100Pic: '/images/prepaid/100-en.jpg',
        buyBtn: 'Buy',
        currency: '$',
      },
      feature: {
        mapListing: 'Business Listing',
        dishListing: 'Dish Listing',
        upto40: 'Up to 40 orders',
        price40: '9',
        upto100: 'Up to 100 orders',
        price100: '24',
        validLabel: 'Valid',
        valid30d: '30 days',
        valid90d: '90 days',
      },
    },

    faq: {
      header: 'Support',
      title: 'Frequently Asked Questions',
      textBlock:
        "Here are the Restaurant FAQs. Feel free to contact us if you can't find what you're looking for",
      questionList: [
        {
          q: 'What is YaMenu?',
          a:
            'It is a low-cost online ordering system that will allow you to publish your menus, dishes, products and maximize your sales. You only prepare, charge and deliver to your customer.',
        },
        {
          q: 'What does it mean that the app is in the "pre-launch stage"?',
          a:
            'The pre-launch stage is a site or an app published for a limited number of users. This limit is to obtain and handle their comments, make adjustments according to specific needs, and fix any detected issue in the processes.',
        },
        {
          q: 'How does it work?',
          a:
            'You create your profile; you share what you sell, your hours, area, and delivery methods. Then you choose your prepaid plan. We currently offer to get up to 40 or 100 orders (credits) in 30 days. For each sale you make, we deduct ONE credit from your balance. It does not matter how many products you sell in each movement, so it will not be necessary for you to increase the prices of your dishes and products to publish your menus dishes or products.',
        },
        {
          q: 'Does YaMenu deliver the orders?',
          a:
            'No. YaMenu is strictly an online order receiving system. The same system allows you to establish for you and your clients how they want to send/receive that order (own delivery service, order and collect, delivery with a local service, etc.).',
        },
        {
          q: 'Can I customize my delivery area?',
          a:
            'Absolutely YES. Part of our differential is that you can select your delivery areas depending on the schedule.',
        },
        {
          q: 'How will customers find my business?',
          a:
            'You can be found by your name, location, or a QR code we will supply to you. What is also important is that you can also be reached by people looking for the kind of food you prepare',
        },
        {
          q: 'May I change my menu and/or prices any time?',
          a:
            'Yes, you can count on our support team to help you out, you can also set different prices for your food based on the time and if they will be delivered to your customer address or takeout',
        },
        {
          q:
            'I cook from home or have a "dark kitchen" and I can only deliver the food but not serve customers onsite, may I also register on YaMenu',
          a:
            'Sure! All food and meal creators can participate (Restaurants, Delis, Dark Kitchens, Cooking at Home, Delivering and so on. If cooking at home or having a "dark kitchen," your address may be kept private and not published.',
        },
        {
          q: 'How do my customers pay?',
          a:
            'Each business establishes its payment methods to let the customer choose from its preferred options. We are already generating new alternatives to make each sale even easier!',
        },
        {
          q: 'Is YaMenu available in my area?',
          a:
            'The answer is always YES! YaMenu is designed to work all over the world.',
        },
        {
          q: 'What are the benefits of becoming a YaMenu partner?',
          a:
            "Avoid dealing with waiting times on the phone or indecisive customers. Through our app, your customers place their orders in real-time, and we notify you instantly. You don't pay sales commissions! Accounts with greater exposure. The value of your smartphone ticket can increase up to 15%! More time to cook = more customers = more income! And all this with zero commissions!",
        },
      ],
    },

    participate: {
      who: "Let's make a Team! Who can become a YaMenu partner ?",
      restaurants: 'Restaurants',
      fastFood: 'Fast Food',
      homemade: 'Homemade',
      darkKitchen: 'Dark Kitchen',
      foodTrucks: 'Food Trucks',
      foodFestivals: 'Food Festivals',
      coffeAndPastry: 'Coffee & Pastry Shops',
    },
    register: {
      contactInfoLabel: 'Contact Info',
      commercialInfoLabel: 'Commercial Info',
      submit: 'Submit',
      title: 'YaMenu Pre-Launch Version Sign Up',
      titleEnhanced1: 'YaMenu',
      titleEnhanced2: 'Pre-Launch',
      titleEnhanced3: 'Version Sign Up',
      textBlock:
        'Complete the form below to be evaluated for a rewarding partnership with YaMenu. Successful applicants will be notified through the contact channels provided during registration so please ensure they are current and accurate.',
      company: 'Business Name',
      email: 'Email',
      phone: 'Phone',
      contact: 'Contact Name',
      comments: 'Comments',
      address: {
        street: 'Street',
        number: 'Number',
        city: 'City',
        state: 'State',
        zip: 'ZIP',
      },
      locationKind: 'Category / Service',
      bizKind: {
        restaurant: 'Restaurant',
        foodTruck: 'Food Truck',
        fastFood: 'Fast Food',
        fromHome: 'Homemade',
        catering: 'Catering Services',
        bakery: 'Bakeries, Pastry Shops and Related',
        other: 'Other',
      },
      deliveryMethod: 'Delivery',
      deliveryKind: {
        walking: 'Walking',
        bike: 'Bike',
        motorcycle: 'Motorcycle',
        car: 'Car',
        other: 'Other',
        none: 'None',
      },
      sentOk: {
        title: 'Thank You!',
        textBlock: 'Thanks for sending your data.',
        avatar: '/images/logo/logo-resto-tb-512x512.png',
        name: 'The Team of YaMenu for Restaurants',
      },
      sentFailure: {
        title: 'Sorry!',
        textBlock:
          'We got an error trying to send your data. Please, try later or send us an email message. Thanks.',
        avatar: '/images/logo/logo-resto-tb-512x512.png',
        name: 'The Team of YaMenu for Restaurants',
      },
    },

    stats: {
      title: 'The Digital Age in the USA',
      statsArr: {
        1: {
          value: '60%',
          text: '60% of U.S. consumers order delivery or takeout once a week.',
        },
        2: {
          value: '60%',
          text:
            '60% of restaurant operators say that offering delivery has generated incremental sales.',
        },
        3: {
          value: '$50+',
          text:
            '34% of consumers spend at least $50 per order when ordering food online.',
        },
      },
    },
    footer: {
      social: {
        header: 'Social',
        facebook: 'https://www.facebook.com/QualityMaidsofLasVegas/',
        instagram: 'https://www.instagram.com/qualitymaidsoflv//',
        twitter: 'https://twitter.com/QualityMaidsLV',
        linkedin: 'https://www.linkedin.com/company/quality-maids-of-las-vegas',
      },
      resources: {
        header: 'Resources',
        faq: '/faq',
      },
      company: {
        header: 'Company',
      },
      terms: {
        header: 'Terms',
        privacy: 'Privacy',
      },
    },
    button: {
      signIn: 'Sign In',
      signUp: 'Sign Up',
    },
    gdpr: {
      msg:
        'We use cookies for a number of reasons, such as keeping YaMenu sites reliable and secure, personalizing content and ads, providing social media features, and analyzing how our sites are used. To find out more about our use of cookies, please see our',
      privacyPolicyLabel: 'Privacy Policy',
      clickMsg: 'Click accept for the best user experience on our app.',
      buttonTextAccept: 'Accept',
      buttonTextDeny: 'Deny',
    },
  },

  buttons: 'Buttons',
  calendar: 'Calendar',
  charts: 'Charts',
  components: 'Components',
  dashboard: 'Dashboard',
  dtables: 'Data Tables',
  eforms: 'Extended Forms',
  error: 'Error Page',
  etables: 'Extended Tables',
  example: 'Example',
  fullscreen: 'Full Screen Map',
  google: 'Google Maps',
  grid: 'Grid System',
  icons: 'Icons',
  lock: 'Lock Screen Page',
  login: 'Login Page',
  maps: 'Maps',
  multi: 'Multi Level Collapse',
  notifications: 'Notifications',
  pages: 'Pages',
  plan: 'Choose Plan',
  pricing: 'Pricing',
  myProfile: 'My Profile',
  editProfile: 'Edit Profile',
  register: 'Register Page',
  rforms: 'Regular Forms',
  rtables: 'Regular Tables',
  rtl: 'RTL Support',
  search: 'Search',
  settings: 'Settings',
  tables: 'Tables',
  tabs: 'Tabs',
  timeline: 'Timeline',
  typography: 'Typography',
  vforms: 'Validation Forms',
  widgets: 'Widgets',
  wizard: 'Wizard',
  page: {
    'biz-account-list': 'Businesses',
    'biz-restaurantLocation-list': 'Locations',
    'biz-dish-list': 'Dishes',
    'biz-menu-list': 'Menus',
    'biz-schedule-list': 'Schedules',
    'biz-iChain-list': 'Intl Chains',
  },
  startPage: {
    welcome: 'Welcome to YaMenu',
    earlyAdopter: 'Want to be an Early Adopter ?',
    earlyAdopterText:
      'Continue with your preferred social network to request access',
  },
  biz: {
    dish: 'Dish',
    dishPl: 'Dishes',
    dishPhoto: 'Dish Photo',
    dishPhotoPl: 'Dish Photos',
    menu: 'Menu',
    menuPl: 'Menus',
    menuSection: 'Menu Section',
    menuSectionPl: 'Menu Sections',
    schedule: 'Schedule',
    schedulePl: 'Schedules',
    restaurantLocation: 'Location',
    restaurantLocationPl: 'Locations',
    hours: 'Hour',
    hoursPl: 'Hours',
  },
  registerPage: {
    look: 'Look',
    lookTxt: 'for your Favorite Food and Cravings',
    discover: 'Discover',
    discoverTxt: 'New places and tasty dishes',
    get: 'Get',
    getTxt: 'Special Offers, Discounts and Gifts',
    continueWithFacebook: 'Continue with Facebook',
    continueWithGoogle: 'Continue with Google',
    signUpSignIn: 'Sign up / Sign in',
  },
  user: {
    userAboutMe: 'About Me',
    userAboutMeHint: 'Tell us about yourself and your preferences',
    userDisplayNameHint: 'Name or Nickname as you will be shown on Reviews',
    userEditProfile: 'My Profile',
    userEditProfileSub: 'Basic Information',
    userEditProfileUpdate: 'Update Profile',
    userEmail: 'Email',
    userFirstName: 'First Name',
    userLastName: 'Last Name',
    userName: 'User Name',
    userNameHint: 'An easy way to help others to find you',
    userProfile: 'User Profile',
    userShowMeAs: 'Show me as',
  },
  date: {
    day: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    },
    month: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    label: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      weekend: 'Weekend',
    },
  },
  optionLabel: {
    intlChain: 'Intl Chains',
    myRestaurants: 'My Restaurants',
    locations: 'Locations',
    dishes: 'Dishes',
    photos: 'Photos',
    openingHours: 'Opening Hours',
    menus: 'Menus',
    new: 'New',
    showAll: 'Show All',
    structures: 'Structures',
  },
  restaurantFormFirst: {
    name: 'Restaurant Name',
    name2ndLine: '2nd line (Pastry House, Steakhouse, Hamburguers, etc',
    email: 'Email',
    kindOfLocation: 'Kind of Location',
    postalAddress: 'Your Restaurant Location',
    yourLogo: 'Your Logo',
    tellUsAboutYourBiz: 'Tell us about your Business',
    restaurant: 'Restaurant',
    kind: 'Kind',
    address: 'Address',
    buildBizProfile: 'Build your Business Profile',
    buildBizProfileSub:
      'This information will let us know more about your restaurant.',
  },
  kindOfBiz: {
    restaurant: 'Restaurant',
    concessionStand: 'Concession Stand',
    foodTruck: 'Food Truck',
    mobileFood: 'Mobile Food',
    streetFood: 'Street Food',
    homeBased: 'Home Based',
    openUp: 'Temporary',
  },
  address: {
    address: 'Address',
    streetName: 'Street Name',
    streetNumber: 'Number',
    suite: 'Suite',
    between1: 'Between',
    between2: 'and',
    intersection: 'Intersection',
    city: 'City',
    state: 'State',
    province: 'Province',
    county: 'County',
    country: 'Country',
    zip: 'Zip',
  },

  countryList: [
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AX', name: 'Åland Islands' },
    { code: 'AL', name: 'Albania' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AD', name: 'Andorra' },
    { code: 'AO', name: 'Angola' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AQ', name: 'Antarctica' },
    { code: 'AG', name: 'Antigua and Barbuda' },
    { code: 'AR', name: 'Argentina' },
    { code: 'AM', name: 'Armenia' },
    { code: 'AW', name: 'Aruba' },
    { code: 'AU', name: 'Australia' },
    { code: 'AT', name: 'Austria' },
    { code: 'AZ', name: 'Azerbaijan' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BB', name: 'Barbados' },
    { code: 'BY', name: 'Belarus' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BZ', name: 'Belize' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BM', name: 'Bermuda' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { code: 'BA', name: 'Bosnia and Herzegovina' },
    { code: 'BW', name: 'Botswana' },
    { code: 'BV', name: 'Bouvet Island' },
    { code: 'BR', name: 'Brazil' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'BN', name: 'Brunei Darussalam' },
    { code: 'BG', name: 'Bulgaria' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'CV', name: 'Cabo Verde' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'CM', name: 'Cameroon' },
    { code: 'CA', name: 'Canada' },
    { code: 'KY', name: 'Cayman Islands' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'TD', name: 'Chad' },
    { code: 'CL', name: 'Chile' },
    { code: 'CN', name: 'China' },
    { code: 'CX', name: 'Christmas Island' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'CO', name: 'Colombia' },
    { code: 'KM', name: 'Comoros' },
    { code: 'CG', name: 'Congo' },
    { code: 'CD', name: 'Congo (the Democratic Republic of the)' },
    { code: 'CK', name: 'Cook Islands' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CI', name: "Côte d'Ivoire" },
    { code: 'HR', name: 'Croatia' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CW', name: 'Curaçao' },
    { code: 'CY', name: 'Cyprus' },
    { code: 'CZ', name: 'Czechia' },
    { code: 'DK', name: 'Denmark' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DM', name: 'Dominica' },
    { code: 'DO', name: 'Dominican Republic' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'EG', name: 'Egypt' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'EE', name: 'Estonia' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'FK', name: 'Falkland Islands / Malvinas' },
    { code: 'FO', name: 'Faroe Islands' },
    { code: 'FJ', name: 'Fiji' },
    { code: 'FI', name: 'Finland' },
    { code: 'FR', name: 'France' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'TF', name: 'French Southern Territories' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GE', name: 'Georgia' },
    { code: 'DE', name: 'Germany' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GR', name: 'Greece' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GU', name: 'Guam' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haiti' },
    { code: 'HM', name: 'Heard Island and McDonald Islands' },
    { code: 'VA', name: 'Holy See' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'HU', name: 'Hungary' },
    { code: 'IS', name: 'Iceland' },
    { code: 'IN', name: 'India' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'IR', name: 'Iran' },
    { code: 'IQ', name: 'Iraq' },
    { code: 'IE', name: 'Ireland' },
    { code: 'IM', name: 'Isle of Man' },
    { code: 'IL', name: 'Israel' },
    { code: 'IT', name: 'Italy' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'JP', name: 'Japan' },
    { code: 'JE', name: 'Jersey' },
    { code: 'JO', name: 'Jordan' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KP', name: "Korea (the Democratic People's Republic of)" },
    { code: 'KR', name: 'Korea (the Republic of)' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KG', name: 'Kyrgyzstan' },
    { code: 'LA', name: "Lao People's Democratic Republic" },
    { code: 'LV', name: 'Latvia' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LR', name: 'Liberia' },
    { code: 'LY', name: 'Libya' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'MO', name: 'Macao' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MY', name: 'Malaysia' },
    { code: 'MV', name: 'Maldives' },
    { code: 'ML', name: 'Mali' },
    { code: 'MT', name: 'Malta' },
    { code: 'MH', name: 'Marshall Islands' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'MR', name: 'Mauritania' },
    { code: 'MU', name: 'Mauritius' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'MX', name: 'Mexico' },
    { code: 'FM', name: 'Micronesia' },
    { code: 'MD', name: 'Moldova' },
    { code: 'MC', name: 'Monaco' },
    { code: 'MN', name: 'Mongolia' },
    { code: 'ME', name: 'Montenegro' },
    { code: 'MS', name: 'Montserrat' },
    { code: 'MA', name: 'Morocco' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'NA', name: 'Namibia' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NP', name: 'Nepal' },
    { code: 'NL', name: 'Netherlands' },
    { code: 'NC', name: 'New Caledonia' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'NE', name: 'Niger' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'NU', name: 'Niue' },
    { code: 'NF', name: 'Norfolk Island' },
    { code: 'MK', name: 'North Macedonia' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'NO', name: 'Norway' },
    { code: 'OM', name: 'Oman' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'PW', name: 'Palau' },
    { code: 'PS', name: 'Palestine' },
    { code: 'PA', name: 'Panama' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Peru' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PN', name: 'Pitcairn' },
    { code: 'PL', name: 'Poland' },
    { code: 'PT', name: 'Portugal' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'QA', name: 'Qatar' },
    { code: 'RE', name: 'Réunion' },
    { code: 'RO', name: 'Romania' },
    { code: 'RU', name: 'Russia' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'BL', name: 'Saint Barthélemy' },
    { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { code: 'KN', name: 'Saint Kitts and Nevis' },
    { code: 'LC', name: 'Saint Lucia' },
    { code: 'MF', name: 'Saint Martin (French part)' },
    { code: 'PM', name: 'Saint Pierre and Miquelon' },
    { code: 'VC', name: 'Saint Vincent and the Grenadines' },
    { code: 'WS', name: 'Samoa' },
    { code: 'SM', name: 'San Marino' },
    { code: 'ST', name: 'Sao Tome and Principe' },
    { code: 'SA', name: 'Saudi Arabia' },
    { code: 'SN', name: 'Senegal' },
    { code: 'RS', name: 'Serbia' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'SG', name: 'Singapore' },
    { code: 'SX', name: 'Sint Maarten (Dutch part)' },
    { code: 'SK', name: 'Slovakia' },
    { code: 'SI', name: 'Slovenia' },
    { code: 'SB', name: 'Solomon Islands' },
    { code: 'SO', name: 'Somalia' },
    { code: 'ZA', name: 'South Africa' },
    { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { code: 'SS', name: 'South Sudan' },
    { code: 'ES', name: 'Spain' },
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SR', name: 'Suriname' },
    { code: 'SJ', name: 'Svalbard and Jan Mayen' },
    { code: 'SE', name: 'Sweden' },
    { code: 'CH', name: 'Switzerland' },
    { code: 'SY', name: 'Syrian Arab Republic' },
    { code: 'TW', name: 'Taiwan' },
    { code: 'TJ', name: 'Tajikistan' },
    { code: 'TZ', name: 'Tanzania' },
    { code: 'TH', name: 'Thailand' },
    { code: 'TL', name: 'Timor-Leste' },
    { code: 'TG', name: 'Togo' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TT', name: 'Trinidad and Tobago' },
    { code: 'TN', name: 'Tunisia' },
    { code: 'TR', name: 'Turkey' },
    { code: 'TM', name: 'Turkmenistan' },
    { code: 'TC', name: 'Turks and Caicos Islands' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'UG', name: 'Uganda' },
    { code: 'UA', name: 'Ukraine' },
    { code: 'AE', name: 'United Arab Emirates' },
    { code: 'GB', name: 'United Kingdom' },
    { code: 'UM', name: 'United States Minor Outlying Islands' },
    { code: 'US', name: 'United States of America' },
    { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Uzbekistan' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'VE', name: 'Venezuela' },
    { code: 'VN', name: 'Viet Nam' },
    { code: 'VG', name: 'Virgin Islands (British)' },
    { code: 'VI', name: 'Virgin Islands (U.S.)' },
    { code: 'WF', name: 'Wallis and Futuna' },
    { code: 'EH', name: 'Western Sahara' },
    { code: 'YE', name: 'Yemen' },
    { code: 'ZM', name: 'Zambia' },
    { code: 'ZW', name: 'Zimbabwe' },
  ],

  navigation: {
    top: 'Top',
    previous: 'Previous',
    previousAbr: 'Prev',
    next: 'Next',
    nextAbr: 'Next',
    finish: 'Finish',
  },
  forms: {
    thisFieldIsRequired: 'This field is required',
  },
  ...messages,
}
