import { render, staticRenderFns } from "./Footer2.vue?vue&type=template&id=5ddc8c36&"
import script from "./Footer2.vue?vue&type=script&lang=js&"
export * from "./Footer2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SocialFacebookBtn: require('/usr/app/components/Social/Facebook/Btn.vue').default,SocialInstagramBtn: require('/usr/app/components/Social/Instagram/Btn.vue').default,Copyright: require('/usr/app/components/Copyright.vue').default})
