// import icons from './icons'
import theme from './theme'
import analytics from './analytics'

export default {
  // product display information
  product: {
    name: 'Quality Maids of Las Vegas',
    version: 'v1.0.1',
  },

  // icon libraries
  // icons,

  // theme configs
  theme,

  // analytics configs
  analytics,
}
